import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { useOrganization } from 'hooks/useOrganization'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Card from '../../../components/ui/Card'
import Page from '../../../components/ui/Page'
import PageHeader from '../../../components/ui/PageHeader'
import { Integration } from '../../../services/integrations/types'
import axios from 'axios'
import { BASE_API_URL } from '../../../constants'
import { useAuth } from 'hooks/useAuth'

export default function GithubSetup() {
  const { activeOrganizationId } = useOrganization()
  const [isLoadingIntegration, setLoadingIntegration] = useState(false)
  const [integration, setIntegration] = useState<Integration | undefined>(undefined)
  const { authCredentials } = useAuth()
  //http://localhost:3001/integrations/github/setup/?installation_id=12682695&setup_action=update

  const [integrationQueryStringParams] = useSearchParams()
  const installationId = integrationQueryStringParams.get('installation_id')
  const setupAction = integrationQueryStringParams.get('setup_action')

  console.log(integrationQueryStringParams, installationId, setupAction)
  const subtitle =
    isLoadingIntegration && integration?.integration_id
      ? 'Integration installed. You can close this window now.'
      : 'Installation failed'

  useEffect(() => {
    if (setupAction === 'install') {
      setLoadingIntegration(true)
      axios
        .post(
          `${BASE_API_URL}/integrations`,
          { installationId: installationId, name: 'github', provider: 'github', organizationId: activeOrganizationId },
          {
            headers: { Authorization: `Bearer ${authCredentials.accessToken}` },
          },
        )
        .then((res) => {
          console.log(res)
          setIntegration(res.data)
          setLoadingIntegration(false)
          window.close()
        })
    }
  }, [activeOrganizationId, installationId, setupAction])

  return (
    <Page>
      <PageHeader title="Github setup" />
      <Card>
        <div className="text-center p-6">
          {subtitle.includes('fail') ? (
            <XCircleIcon className="mx-auto h-12 w-12 text-red-400" />
          ) : (
            <CheckCircleIcon className="mx-auto h-12 w-12 text-green-400" />
          )}
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            {subtitle.includes('fail') ? 'Failure' : 'Success'}
          </h3>
          {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
        </div>
      </Card>
    </Page>
  )
}
