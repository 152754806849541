import { useCallback } from 'react'
import { useAppSelector } from './redux'
import { useDispatch } from 'react-redux'
import { updateActiveOrganizationId } from 'slices/organization/reducer'

export const useOrganization = (): any => {
  const dispatch = useDispatch()
  const user = useAppSelector((state) => state.auth.user)
  const activeOrganizationId = useAppSelector((state) => state.organization.activeOrganizationId)
  //   const isLoading = useAppSelector((state) => state.organization.isLoading)
  const memberships = useAppSelector((state) => state.organization.memberships)

  const activeOrganizationMembership =
    memberships.find((m) => m.organization.id.toLowerCase() === activeOrganizationId?.toLowerCase()) || memberships[0]
  const activeOrganization = memberships.find(
    (m) => m.organization.id.toLowerCase() === activeOrganizationId?.toLowerCase(),
  )?.organization
  const personalWorkspace = memberships.find((m) => m.organization.name.toLowerCase() === user.email?.toLowerCase())
  const organizations = memberships.filter((m) => m.organization.name.toLowerCase() !== user.email?.toLowerCase())

  const handleOrganizationChange = useCallback(
    async (organizationId: string) => {
      dispatch(updateActiveOrganizationId(organizationId))
    },
    [dispatch],
  )

  //   useEffect(() => {
  //     if (!isLoading && activeOrganization) {
  //       handleOrganizationChange(selectedOrganization)
  //     }
  //   }, [user, isLoading, activeOrganization, handleOrganizationChange])

  return {
    personalWorkspace,
    activeOrganizationId,
    activeOrganizationMembership,
    activeOrganization,
    organizations,
    handleOrganizationChange,
  }
}
