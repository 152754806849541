import { Routes, Route } from 'react-router-dom'

// import Dashboard from '../layout/Dashboard'
// import NotFound from '../pages/NotFound'
import { ROUTES_CONFIG } from './routes'

export default function BaseRouter(): JSX.Element {
  return (
    <Routes>
      {ROUTES_CONFIG.map(
        ({
          title,
          path,
          layout: Layout,
          page: Page,
          subRoutes,
        }: {
          title: string
          path: string
          layout: any
          page: any
          subRoutes: any
        }) => (
          <Route
            key={title}
            path={path}
            element={
              <Layout>
                <Page />
              </Layout>
            }
          >
            {subRoutes.length
              ? subRoutes.map(({ subPage: SubPage, ...props }) => (
                  <Route key={props.title} path={props.path} element={<SubPage />} />
                ))
              : null}
          </Route>
        ),
      )}
    </Routes>
  )
}
