import Avatar from 'components/ui/Avatar'
import Empty from 'components/ui/Empty'
import Skeleton from 'components/ui/Skeleton'
import UAParser from 'ua-parser-js'
import { convertISOToDateString } from 'utils/dates'

export default function EventTable({ events, isLoading }: { events: Event[]; isLoading: boolean }) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="dark:text-slate-100">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-100 sm:pl-6 md:pl-0"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-100"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-100"
                >
                  User agent
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-100"
                >
                  IP Address
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-slate-100"
                >
                  Timestamp (UTC)
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {isLoading ? (
                Array.from(Array(3)).map((_, idx: number) => (
                  <tr key={idx}>
                    <td className="flex items-center space-x-2 py-4 pl-4 pr-3 sm:pl-6 md:pl-0">
                      <Avatar text={idx.toString()} isLoading={true} />
                      <span className="flex-1 flex flex-col min-w-[6rem] space-y-1">
                        <Skeleton className="h-5 w-full" />
                        <Skeleton className="h-5 w-full" />
                      </span>
                    </td>
                    <td className="py-4 px-3">
                      <Skeleton className="h-5 w-full" />
                    </td>
                    <td className="py-4 px-3">
                      <Skeleton className="h-5 w-full" />
                    </td>
                    <td className="py-4 px-3">
                      <Skeleton className="h-5 w-full" />
                    </td>
                    <td className="py-4 px-3">
                      <Skeleton className="h-5 w-full" />
                    </td>
                  </tr>
                ))
              ) : events && events.length ? (
                events.map((event: any) => {
                  const uaParser = new UAParser(event.userAgent)
                  const userAgent = uaParser.setUA(event.userAgent).getResult()
                  return (
                    <tr key={event.event_id}>
                      <td className="whitespace-nowrap flex items-center space-x-2 py-4 pl-4 pr-3 text-gray-900 sm:pl-6 md:pl-0">
                        {/* <Avatar text={event.description} /> */}
                        <div className="text-left  items-center space-y-1">
                          <div className="text-sm font-medium truncate max-w-[8rem]">
                            {/* {typeof event.actor === 'string' ? event.actor : event.actor.email || '-'} */}
                          </div>
                          <div className="text-sm font-normal text-gray-500 truncate max-w-[18rem]">
                            {event.description}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {event.actionObjectType}.{event.actionType}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {userAgent.ua ? (
                          <div className="flex items-center space-x-1">
                            <div>{userAgent.browser.name}</div>
                            <div>({userAgent.os.name})</div>
                          </div>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{event.ipAddress}</td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {convertISOToDateString(event.createdOn)}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={5} className="p-6 sm:p-8">
                    <Empty title="No events" subtitle="Looks like there is no organization activity" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
