import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useCallback, useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import Button from './ui/Button'
import InputLabel from './ui/InputLabel'
import Input from './ui/Input'
import axios from 'axios'
import { BASE_API_URL } from '../constants'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchOrganizationMembershipList } from 'slices/organization/reducer'

const initialValues = { name: '' }

export default function CreateOrganizationModal() {
  const dispatch = useAppDispatch()

  const authCredentials = useAppSelector((state) => state.auth.authCredentials)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [organizationForm, setOrganizationForm] = useState(initialValues)

  const handleFormChange = useCallback(
    ({ name, value }: { name: string; value: any }) => {
      setOrganizationForm({ ...organizationForm, [name]: value })
    },
    [organizationForm],
  )

  const handleSubmit = async () => {
    await axios
      .post(
        `${BASE_API_URL}/organizations`,
        { ...organizationForm },
        {
          headers: { Authorization: `Bearer ${authCredentials.accessToken}` },
        },
      )
      .then((res) => {
        dispatch(fetchOrganizationMembershipList())
        closeModal()
      })
  }

  function closeModal() {
    setIsOpen(false)
    setOrganizationForm(initialValues)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div
        className="cursor-pointer flex space-x-2 text-sm p-2.5 border-t border-slate-100 dark:border-slate-500"
        onClick={openModal}
      >
        <PlusCircleIcon className="w-4 h-5 text-slate-400" />
        <div>Create organization</div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 p-6 text-left align-middle shadow-xl transition-all">
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-slate-100">
                      Create Organization
                    </Dialog.Title>

                    <div className="mt-6 mb-8">
                      <InputLabel label="Name" />
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={organizationForm?.name}
                        onChange={(e) => handleFormChange({ name: 'name', value: e.target.value })}
                      />
                    </div>

                    <div className="flex items-center justify-end space-x-3">
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button type="submit" onClick={handleSubmit}>
                        Create
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
