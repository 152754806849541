import { Link } from 'react-router-dom'
import Avatar from '../../../components/ui/Avatar'
// import MoreMenu from 'components/common/MoreMenu'

export default function IntegrationListItem({ integration }: { integration }) {
  const { id, name } = integration
  return (
    <li key={id} className="block hover:bg-slate-50 dark:hover:bg-slate-700 first:rounded-t-md last:rounded-b-md">
      <Link to={`/settings/teams/${id}`}>
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <Avatar text={name} size="lg" variant="square" />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 dark:text-indigo-500 truncate">{name}</p>
                <p className="mt-2 flex items-center text-sm text-slate-500 dark:text-slate-400">
                  {/* <span className="truncate">{description}</span> */}
                </p>
              </div>
            </div>
          </div>
          <div>{/* <MoreMenu team={team} /> */}</div>
        </div>
      </Link>
    </li>
  )
}
