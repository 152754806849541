import clsx from 'clsx'
import { Link, Outlet, useLocation } from 'react-router-dom'

const secondaryNavigation = [
  { name: 'Account', href: '/settings', current: true },
  { name: 'Teams', href: '/settings/teams', current: false },
  { name: 'Billing', href: '/settings/billing', current: false },
  { name: 'Integrations', href: '/settings/integrations', current: false },
  { name: 'Events', href: '/settings/events', current: false },
]

export default function Settings() {
  const location = useLocation()
  return (
    <>
      <header className="border-b border-white/5">
        {/* Secondary navigation */}
        <nav className="flex overflow-x-auto py-3">
          <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8">
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <Link to={item.href} className={clsx({ 'text-indigo-400': location.pathname === item.href })}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <Outlet />
    </>
  )
}
