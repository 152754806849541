import axios, { AxiosInstance } from 'axios'
import { BASE_API_URL } from './constants'

export class BaseClient {
  apiClient: AxiosInstance
  baseUrl: string

  constructor({ baseUrl, apiKey }: { baseUrl?: string; apiKey?: string }) {
    const headers = {}
    headers['Authorization'] = apiKey ? `Bearer ${apiKey}` : null

    this.baseUrl = baseUrl || BASE_API_URL
    this.apiClient = axios.create({
      baseURL: this.baseUrl,
      headers,
    })
  }
}
