import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { useAppDispatch } from 'hooks/redux'
// import { fetchOrganizationMembershipList } from 'slices/organization/reducer'
import { BellIcon } from '@heroicons/react/24/outline'

export default function NotificationDropdown() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // dispatch(fetchOrganizationMembershipList())
  }, [dispatch])

  const activityItems = [
    {
      user: {
        name: 'Michael Foster',
        imageUrl:
          'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      projectName: 'ios-app',
      commit: '2d89f0c8',
      branch: 'main',
      date: '1h',
      dateTime: '2023-01-23T11:00',
    },
    {
      user: {
        name: 'Lindsay Walton',
        imageUrl:
          'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      projectName: 'mobile-api',
      commit: '249df660',
      branch: 'main',
      date: '3h',
      dateTime: '2023-01-23T09:00',
    },
    {
      user: {
        name: 'Courtney Henry',
        imageUrl:
          'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      projectName: 'ios-app',
      commit: '11464223',
      branch: 'main',
      date: '12h',
      dateTime: '2023-01-23T00:00',
    },
    {
      user: {
        name: 'Courtney Henry',
        imageUrl:
          'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      projectName: 'tailwindui.com',
      commit: 'dad28e95',
      branch: 'main',
      date: '2d',
      dateTime: '2023-01-21T13:00',
    },
    {
      user: {
        name: 'Michael Foster',
        imageUrl:
          'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      projectName: 'relay-service',
      commit: '624bc94c',
      branch: 'main',
      date: '5d',
      dateTime: '2023-01-18T12:34',
    },
  ]

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center justify-center rounded-lg bg-slate-400 dark:bg-slate-700 bg-opacity-20 px-1.5 py-1.5 text-sm font-medium dark:text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-slate-700 ">
            <BellIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 mt-2 w-80 origin-bottom-right rounded-md bg-white dark:bg-slate-700 dark:text-slate-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-96">
            <ul className="divide-y divide-white/5">
              {activityItems.map((item) => (
                <Menu.Item>
                  <li key={item.commit} className="py-4 px-4">
                    <div className="flex items-center gap-x-3">
                      <img src={item.user.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-gray-800" />
                      <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-white">
                        {item.user.name}
                      </h3>
                      <time dateTime={item.dateTime} className="flex-none text-xs text-gray-500">
                        {item.date}
                      </time>
                    </div>
                    <p className="mt-3 truncate text-sm text-gray-500">
                      Pushed to <span className="text-gray-400">{item.projectName}</span> (
                      <span className="font-mono text-gray-400">{item.commit}</span> on{' '}
                      <span className="text-gray-400">{item.branch}</span>)
                    </p>
                  </li>
                </Menu.Item>
              ))}
            </ul>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
