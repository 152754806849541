import { BaseClient } from '../BaseClient'
import { ApiRequestHeaders } from '../BaseClient/types'
import { ProjectListResponse, ProjectResponse } from './types'

export class ProjectClient extends BaseClient {
  constructor({ apiKey }: { apiKey: string }) {
    super({ apiKey })
  }

  async createProject(
    {
      organizationId,
      name,
      description,
      teams,
      visibility,
    }: {
      organizationId: string
      name: string
      visibility: string
      description?: string
      teams?: string[]
    },
    headers?: ApiRequestHeaders,
  ): ProjectResponse {
    return this.apiClient.post(
      `/organizations/${organizationId}/projects`,
      {
        name,
        description,
        teams,
        visibility,
      },
      headers,
    )
  }

  async fetchProjectList(
    { organizationId }: { organizationId: string },
    headers?: ApiRequestHeaders,
  ): ProjectListResponse {
    return this.apiClient.get(`/organizations/${organizationId}/projects`, headers)
  }

  async fetchProjectById({
    organizationId,
    projectId,
    includeTeams,
  }: {
    organizationId: string
    projectId: string
    includeTeams?: boolean
  }): ProjectResponse {
    return this.apiClient.get(`/organizations/${organizationId}/projects/${projectId}`, {
      params: { teams: includeTeams },
    })
  }

  async deleteProject({ organizationId, projectId }: { organizationId: string; projectId: string }): ProjectResponse {
    return this.apiClient.delete(`/organizations/${organizationId}/projects/${projectId}`)
  }
}
