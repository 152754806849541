import SectionHeader from '../../../components/ui/SectionHeader'
import IntegrationList from './IntegrationList'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useEffect } from 'react'
import CreateIntegrationModal from './CreateIntegrationModal'
import { fetchIntegrationList } from 'slices/integration/reducer'

function Integrations() {
  const dispatch = useAppDispatch()
  const activeOrganizationId = useAppSelector((state) => state.organization.activeOrganizationId)
  const integrations = useAppSelector((state) => state.integration.integrations)

  useEffect(() => {
    if (activeOrganizationId) {
      dispatch(fetchIntegrationList({ organizationId: activeOrganizationId }))
    }
  }, [activeOrganizationId, dispatch])

  return (
    <div className="mx-6 my-4">
      <SectionHeader
        title={'Integrations'}
        actions={
          <>
            <CreateIntegrationModal />
          </>
        }
      />
      <IntegrationList integrations={integrations} isLoading={false} />
    </div>
  )
}

export default Integrations
