import { ReactNode, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Uh oh, something went wrong:</p>
      <pre>{error.message}</pre>
      <button
        onClick={() => (window.location.href = window.location.origin)}
        className="bg-indigo-500 text-white px-3 py-2 rounded-lg"
      >
        Go home
      </button>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export const BaseErrorBoundary = ({ children }: { children: ReactNode }) => {
  const handleError = useCallback(() => {
    console.error('ERROR CAUGHT')
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  )
}
