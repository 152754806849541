import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from './redux'
import { signout } from 'slices/auth/reducer'

export const useAuth = (): any => {
  const dispatch = useAppDispatch()
  const authCredentials = useAppSelector((state) => state.auth.authCredentials)

  useEffect(() => {
    if (!authCredentials || parseInt(authCredentials.accessTokenClaims.exp) * 1000 < Date.now()) {
      dispatch(signout())
    }
  }, [authCredentials, dispatch])

  return { authCredentials }
}
