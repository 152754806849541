import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useEffect } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import { fetchProjectDetail } from 'slices/project/reducer'

const secondaryNavigation = [
  { name: 'Overview', href: '', current: true },
  { name: 'CI/CD', href: '/cicd', current: false },
  { name: 'Docs', href: '/docs', current: false },
  { name: 'Dependencies', href: '/dependencies', current: false },
  { name: 'Insights', href: '/insights', current: false },
  { name: 'Activity', href: '/activity', current: false },
  { name: 'Settings', href: '/settings', current: false },
]

export default function Index() {
  const { projectId } = useParams()
  const organizationId = useAppSelector((state) => state.organization.activeOrganizationId)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (organizationId && projectId) {
      dispatch(fetchProjectDetail({ organizationId, projectId }))
    }
  }, [dispatch, organizationId, projectId])

  return (
    <div>
      <main>
        <header>
          {/* Secondary navigation */}
          <nav className="flex overflow-x-auto border-b border-white/10 py-4">
            <ul
              role="list"
              className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link to={`/projects/${projectId}${item.href}`} className={item.current ? 'text-indigo-400' : ''}>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>
        <Outlet />
      </main>
    </div>
  )
}
