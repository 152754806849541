import { SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_API_URL } from '../../constants'

const initialState: {
  isLoading: boolean
  currentRequestId: string | undefined
  integrations: any[]
  error: undefined | SerializedError
} = {
  isLoading: false,
  currentRequestId: undefined,
  integrations: [],
  error: undefined,
}

export const fetchIntegrationList = createAsyncThunk(
  'integration/fetchList',
  async ({ organizationId }: { organizationId: string }, { getState }): Promise<any[]> => {
    const state: any = await getState()
    const jwt = state.auth.authCredentials?.accessToken
    const { data } = await axios.get(`${BASE_API_URL}/organizations/${organizationId}/integrations`, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    return data
  },
)

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntegrationList.pending, (state, action) => {
        if (!state.isLoading) {
          state.isLoading = true
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(fetchIntegrationList.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.integrations = action.payload
          state.currentRequestId = undefined
        }
      })
      .addCase(fetchIntegrationList.rejected, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      })
  },
})

export default integrationSlice.reducer
