export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const padZeros = (amount: number | string, length = 2) => {
  let stringifiedAmount = String(amount)
  for (let i = stringifiedAmount.length; i < length; i++) {
    stringifiedAmount = '0' + stringifiedAmount
  }
  return stringifiedAmount
}

export const convertUnixToDateVars = (unixTimestamp: number) => {
  const datetime = new Date(unixTimestamp * 1000)
  const month = months[datetime.getMonth()]
  const date = datetime.getDate()
  const shortDay = datetime.toLocaleString('en-us', { weekday: 'short' })
  const longDay = datetime.toLocaleString('en-us', { weekday: 'long' })
  const hour = datetime.getHours()
  const moduloHour = hour % 12
  const displayHour = padZeros(moduloHour === 0 ? 12 : moduloHour)
  const amPm = hour > 11 ? 'pm' : 'am'
  const min = padZeros(datetime.getMinutes())
  return { month, date, hour: displayHour, min, amPm, shortDay, longDay }
}

export const convertUnixToDateString = (unixTimestamp: number) => {
  const { month, date, hour, min, amPm } = convertUnixToDateVars(unixTimestamp)
  return `${month} ${date} at ${hour}:${min} ${amPm}`
}

export const convertISOToDateString = (timestamp: string, dateOnly = false) => {
  const unixTimestamp = Math.floor(new Date(timestamp).getTime() / 1000)
  const { month, date, hour, min, amPm } = convertUnixToDateVars(unixTimestamp)
  return dateOnly ? `${month} ${date}` : `${month} ${date} at ${hour}:${min} ${amPm}`
}

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
  { unit: 'year', ms: 24 * 60 * 60 * 1000 * 365 },
  { unit: 'month', ms: (24 * 60 * 60 * 1000 * 365) / 12 },
  { unit: 'day', ms: 24 * 60 * 60 * 1000 },
  { unit: 'hour', ms: 60 * 60 * 1000 },
  { unit: 'minute', ms: 60 * 1000 },
  { unit: 'second', ms: 1000 },
]

const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

export const getRelativeTime = (date1: Date, date2 = new Date()) => {
  const elapsedTime = date1.getTime() - date2.getTime()

  for (const { unit, ms } of units) {
    if (Math.abs(elapsedTime) > ms || unit === 'second') {
      return rtf.format(Math.round(elapsedTime / ms), unit)
    }
  }
  return ''
}
