import { SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_API_URL } from './../../constants'

const initialState: {
  isLoading: boolean
  currentRequestId: string | undefined
  activeOrganizationId: string | undefined
  organizations: any[]
  memberships: any[]
  error: undefined | SerializedError
} = {
  isLoading: false,
  currentRequestId: undefined,
  activeOrganizationId: undefined,
  organizations: [],
  memberships: [],
  error: undefined,
}

export const fetchOrganizationMembershipList = createAsyncThunk(
  'organizations/memberships',
  async (test, { getState }): Promise<any[]> => {
    const state: any = await getState()
    const jwt = state.auth.authCredentials?.accessToken
    const { data: memberships } = await axios.get(`${BASE_API_URL}/organizations/memberships`, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    return memberships
  },
)

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    updateActiveOrganizationId: (state, action) => {
      state.activeOrganizationId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationMembershipList.pending, (state, action) => {
        if (!state.isLoading) {
          state.isLoading = true
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(fetchOrganizationMembershipList.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.memberships = action.payload
          state.currentRequestId = undefined
        }
      })
      .addCase(fetchOrganizationMembershipList.rejected, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      })
  },
})

export const { updateActiveOrganizationId } = organizationSlice.actions

export default organizationSlice.reducer
