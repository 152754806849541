import axios from 'axios'
import { BASE_API_URL, REACT_APP_GITHUB_CLIENT_ID, REACT_APP_HOST_URL } from '../../constants'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { signin } from '../../slices/auth/reducer'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useGoogleLogin } from '@react-oauth/google'
import GoogleBlockLogo from '../../assets/GoogleBlockLogo.svg'
import Button from '../../components/ui/Button'
import BlockLogo from '../../assets/blockLogo.svg'
import { updateActiveOrganizationId } from '../../slices/organization/reducer'
import { ROUTES } from 'routes/routes'

export default function Signup() {
  const [email, setEmail] = useState<undefined | string>(undefined)
  const [password, setPassword] = useState<undefined | string>(undefined)
  const [error, setError] = useState<undefined | string>(undefined)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const authCredentials = useAppSelector((state) => state.auth.authCredentials)

  const handleSignup = async ({ email, password }: { email: string | undefined; password: string | undefined }) => {
    try {
      const { data } = await axios.post(`${BASE_API_URL}/auth/signup`, { email, password })
      const defaultOrganizationId = data.user?.memberships?.length
        ? data.user?.memberships[0]?.organizationId
        : undefined
      dispatch(updateActiveOrganizationId(defaultOrganizationId))
      dispatch(signin(data))
    } catch (e: any) {
      const statusCode = e.response.status
      if (statusCode === 409) {
        setError('Account with that email already exists')
      }
      console.log(e)
    }
  }

  const handleGoogleSignin = useGoogleLogin({
    onSuccess: async (response) => {
      if (response?.access_token) {
        const accessToken = response.access_token

        const { data } = await axios.post(`${BASE_API_URL}/auth/google`, {
          token: accessToken,
          provider: 'google',
        })
        const defaultOrganizationId = data.user?.memberships?.length
          ? data.user?.memberships[0]?.organizationId
          : undefined
        console.log(defaultOrganizationId)
        dispatch(updateActiveOrganizationId(defaultOrganizationId))
        dispatch(signin(data))
      }
    },
  })

  useEffect(() => {
    if (authCredentials?.accessToken) {
      navigate('/')
    }
  }, [authCredentials, navigate])

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-10 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href={'https://developerportal.io'}>
            <img className="h-10 mx-auto" src={BlockLogo} alt="DeveloperPortal" />
          </a>
          <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white text-slate-900">
            Signup for an account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6" onSubmit={() => handleSignup({ email, password })}>
            {error}
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 dark:text-white text-slate-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 dark:text-white text-slate-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a href="#" className="font-semibold dark:text-indigo-400 text-indigo-500 hover:text-indigo-300">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                // type="submit"
                onClick={() => handleSignup({ email, password })}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Sign up
              </button>
            </div>
          </div>

          <div>
            <div className="relative mt-10">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white dark:bg-gray-900 px-6 text-gray-900 dark:text-white">Or signin with</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-2 gap-4">
              {/* <a
                href="#"
                className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
              >
                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </a> */}
              <Button className="bg-blue-500 space-x-3 items-center" onClick={handleGoogleSignin}>
                <img className="h-5 w-5" src={GoogleBlockLogo} />
                <span>Google</span>
              </Button>

              <a
                className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                href={`https://github.com/login/oauth/authorize?scope=user&client_id=${REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${REACT_APP_HOST_URL}/signin`}
                target="popup"
              >
                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6">GitHub</span>
              </a>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-400">
            Already a member?{' '}
            <Link to={ROUTES.SIGNIN} className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
              Signin
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}
