import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const settings = [
  {
    name: 'Public',
    value: 'public',
    description: 'This projects documentation would be available to anyone who has the link',
  },
  {
    name: 'Internal',
    value: 'internal',
    description: 'Only members of this organization would be able to access',
  },
  {
    name: 'Private',
    value: 'private',
    description: 'Only members of this project would be able to access',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProjectVisibility({ handleFormChange }: { handleFormChange }) {
  const [selected, setSelected] = useState(settings[0])

  const handleChange = (e) => {
    setSelected(e)
    handleFormChange({ name: 'visibility', value: e.value })
  }

  return (
    <RadioGroup value={selected} onChange={handleChange}>
      <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px dark:bg-slate-800 dark:border-slate-600">
        {settings.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked
                  ? 'bg-indigo-50 border-indigo-200 dark:border-indigo-600 dark:bg-slate-900 z-10'
                  : 'border-gray-200 dark:border-slate-600',
                'relative border p-4 flex cursor-pointer focus:outline-none',
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-indigo-600 border-transparent'
                      : 'bg-white border-gray-300 dark:border-slate-600 dark:bg-slate-900',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full border flex items-center justify-center',
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white dark:bg-slate-900 w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-900 dark:text-indigo-50' : 'text-gray-900 dark:text-slate-100',
                      'block text-sm font-medium',
                    )}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-700 dark:text-indigo-300' : 'text-gray-500',
                      'block text-sm',
                    )}
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
