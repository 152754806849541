import { LockOpenIcon, ChatBubbleLeftEllipsisIcon, CalendarIcon, CodeBracketIcon } from '@heroicons/react/24/outline'
import Avatar from 'components/ui/Avatar'
import { useAppSelector } from 'hooks/redux'
import { Link } from 'react-router-dom'

export default function Overview() {
  const currentProject = useAppSelector((state) => state.project.currentProject)
  console.log(currentProject)

  return (
    <div className="border-t border-white/10 flex-1">
      <div className="py-8 xl:py-10">
        <div className="mx-auto max-w-3xl xl:grid xl:max-w-5xl xl:grid-cols-3">
          <div className="xl:col-span-2 xl:border-r xl:border-gray-200 xl:pr-8">
            <div>
              <div>
                <aside className="xl:hidden">
                  <h2 className="sr-only">Details</h2>
                  <div className="space-y-5">
                    <div className="flex items-center space-x-2">
                      <LockOpenIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-sm font-medium text-green-700">Open Issue</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-sm font-medium text-gray-900">4 comments</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-sm font-medium text-gray-900">
                        Created on <time dateTime="2020-12-02">Dec 2, 2020</time>
                      </span>
                    </div>
                  </div>
                  <div className="mt-6 space-y-8 border-b border-t border-gray-200 py-6">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">Assignees</h2>
                      <ul role="list" className="mt-3 space-y-3">
                        <li className="flex justify-start">
                          <a href="#" className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-5 w-5 rounded-full"
                                src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">Eduardo Benz</div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">Tags</h2>
                      <ul role="list" className="mt-2 leading-8">
                        <li className="inline">
                          <a
                            href="#"
                            className="relative inline-flex items-center rounded-full px-2.5 py-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <div className="absolute flex flex-shrink-0 items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
                            </div>
                            <div className="ml-3 text-xs font-semibold text-gray-900">Bug</div>
                          </a>{' '}
                        </li>
                        <li className="inline">
                          <a
                            href="#"
                            className="relative inline-flex items-center rounded-full px-2.5 py-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <div className="absolute flex flex-shrink-0 items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true" />
                            </div>
                            <div className="ml-3 text-xs font-semibold text-gray-900">Accessibility</div>
                          </a>{' '}
                        </li>
                      </ul>
                    </div>
                  </div>
                </aside>
                <div className="py-3 xl:pb-0 xl:pt-6 dark:text-slate-100">
                  <h2 className="sr-only">Description</h2>
                  <div className="prose max-w-none">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, hic? Commodi cumque similique
                      id tempora molestiae deserunt at suscipit, dolor voluptatem, numquam, harum consequatur laboriosam
                      voluptas tempore aut voluptatum alias?
                    </p>
                    <ul role="list">
                      <li>
                        Tempor ultrices proin nunc fames nunc ut auctor vitae sed. Eget massa parturient vulputate
                        fermentum id facilisis nam pharetra. Aliquet leo tellus.
                      </li>
                      <li>Turpis ac nunc adipiscing adipiscing metus tincidunt senectus tellus.</li>
                      <li>
                        Semper interdum porta sit tincidunt. Dui suspendisse scelerisque amet metus eget sed. Ut tellus
                        in sed dignissim.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside className="hidden xl:block xl:pl-8">
            <h2 className="sr-only">Details</h2>
            <div className="space-y-5">
              <div className="flex items-center space-x-2">
                <LockOpenIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                <span className="text-sm font-medium text-green-700">{currentProject?.visibility}</span>
              </div>
              <div className="flex items-center space-x-2">
                <CodeBracketIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span className="text-sm font-medium text-gray-900 dark:text-slate-100">
                  {currentProject?.sourceCodeUrl}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span className="text-sm font-medium text-gray-900 dark:text-slate-100">
                  Created on <time dateTime={currentProject?.createdOn}>{currentProject?.createdOn}</time>
                </span>
              </div>
            </div>
            <div className="mt-6 space-y-8 border-t border-gray-200 py-6">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Owners</h2>
                <ul className="mt-3 space-y-3">
                  {currentProject?.teams.map((team) => (
                    <li key={team.id}>
                      <Link to={`/settings/teams/${team.id}`} className="flex gap-x-4">
                        <Avatar size="sm" text={team.name} />
                        <div className="min-w-0">
                          <p className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-100">
                            {team.name}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h2 className="text-sm font-medium text-gray-500">Teams</h2>
                <ul className="mt-3 space-y-3">
                  {currentProject?.teams.map((team) => (
                    <li key={team.id}>
                      <Link to={`/settings/teams/${team.id}`} className="flex gap-x-4">
                        <Avatar size="sm" text={team.name} />
                        <div className="min-w-0">
                          <p className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-100">
                            {team.name}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h2 className="text-sm font-medium text-gray-500">Tags</h2>
                <ul role="list" className="mt-2 leading-8">
                  <li className="inline">
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-full px-2.5 py-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <div className="absolute flex flex-shrink-0 items-center justify-center">
                        <span className="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
                      </div>
                      <div className="ml-3 text-xs font-semibold text-gray-900 dark:text-slate-100">Bug</div>
                    </a>{' '}
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}
