import { PaymentElement } from '@stripe/react-stripe-js'

function Billing() {
  return (
    <div className="mx-6 my-4">
      <PaymentElement options={{ paymentMethodOrder: ['card', 'apple_pay', 'google_pay'] }} />
    </div>
  )
}

export default Billing
