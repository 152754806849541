// layouts
import Dashboard from '../layout/Dashboard'

// pages
import Signup from '../pages/signup'
import Signin from '../pages/signin'
import Home from '../pages/home'
import Main from '../layout/Main'
import Settings from 'pages/settings'
import Billing from 'pages/settings/Billing'
import Account from 'pages/settings/Account'
import CreateProject from 'pages/projects/createProject/CreateProject'
import ProjectDetail from 'pages/projects/projectDetail/index'
import Events from 'pages/settings/Events'
import Teams from 'pages/settings/Teams'
import Overview from 'pages/projects/projectDetail/Overview'
import Docs from 'pages/projects/projectDetail/Docs'
import Integrations from 'pages/settings/Integrations'
import GithubSetup from 'pages/settings/Integrations/GithubSetup'
import Templates from 'pages/templates'
import Insights from 'pages/insights'
import Projects from 'pages/projects'

interface Routes {
  [key: string]: string
}

export const ROUTES: Routes = {
  HOME: '/',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  FEED: '/feed',
  PROJECTS: '/projects',
  INSIGHTS: '/insights',
  TEMPLATES: '/templates',
  DOCS: '/docs',
  CREATE_PROJECT: '/projects/create',
  CREATE_TEAM: '/teams/create',
  PROJECT_DETAIL: '/projects/:projectId',
  PROJECT_DOCS: '/projects/:projectId/docs',
  GITHUB_SETUP: '/integrations/github/setup',
  SETTINGS: '/settings',
  SETTINGS_ACCOUNT: '/settings/',
  SETTINGS_BILLING: '/settings/billing',
  SETTINGS_EVENTS: '/settings/events',
  SETTINGS_INTEGRATIONS: '/settings/integrations',
  SETTINGS_INTEGRATION_SETUP: '/settings/integrations/:integrationId/setup',
  ORGANIZATION_SETTINGS_TEAMS: '/settings/teams',
  ORGANIZATION_SETTINGS_TEAM_DETAIL: '/settings/teams/:teamId',
  ORGANIZATION_SETTINGS_MEMBERS: '/settings/members',
  ORGANIZATION_SETTINGS_INTEGRATIONS: '/settings/integrations',
  ORGANIZATION_SETTINGS_INTEGRATION_DETAIL: '/settings/integrations/:integrationId',
  USER_SETTINGS: '/user-settings',
}

export const ROUTES_CONFIG = [
  { title: 'Home', path: ROUTES.HOME, layout: Dashboard, page: Home, protected: true, subRoutes: [] },
  { title: 'Signup', path: ROUTES.SIGNUP, layout: Main, page: Signup, protected: false, subRoutes: [] },
  { title: 'Signin', path: ROUTES.SIGNIN, layout: Main, page: Signin, protected: false, subRoutes: [] },
  { title: 'Projects', path: ROUTES.PROJECTS, layout: Dashboard, page: Projects, protected: false, subRoutes: [] },
  { title: 'Insights', path: ROUTES.INSIGHTS, layout: Dashboard, page: Insights, protected: false, subRoutes: [] },
  { title: 'Templates', path: ROUTES.TEMPLATES, layout: Dashboard, page: Templates, protected: false, subRoutes: [] },
  {
    title: 'Create project',
    path: ROUTES.CREATE_PROJECT,
    layout: Dashboard,
    page: CreateProject,
    protected: false,
    subRoutes: [],
  },
  {
    title: 'Project',
    path: ROUTES.PROJECT_DETAIL,
    layout: Dashboard,
    page: ProjectDetail,
    protected: false,
    subRoutes: [
      { title: 'Overview', path: ROUTES.PROJECT_DETAIL + '/', subPage: Overview },
      { title: 'Docs', path: ROUTES.PROJECT_DOCS, subPage: Docs },
    ],
  },
  {
    title: 'Settings',
    path: ROUTES.SETTINGS,
    layout: Dashboard,
    page: Settings,
    subRoutes: [
      { title: 'Account', path: ROUTES.SETTINGS_ACCOUNT, subPage: Account },
      { title: 'Teams', path: ROUTES.ORGANIZATION_SETTINGS_TEAMS, subPage: Teams },
      { title: 'Billing', path: ROUTES.SETTINGS_BILLING, subPage: Billing },
      { title: 'Events', path: ROUTES.SETTINGS_EVENTS, subPage: Events },
      { title: 'Integrations', path: ROUTES.SETTINGS_INTEGRATIONS, subPage: Integrations },
      { title: 'Integration Setup', path: ROUTES.SETTINGS_INTEGRATION_SETUP, subPage: GithubSetup },
    ],
  },
]
