import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid'
import Loader from 'components/ui/Loader'

export default function SelectTeamsInput({
  teams,
  handleFormChange,
  isLoading,
}: {
  teams: any[]
  handleFormChange: any
  isLoading: boolean
}) {
  const [selectedTeams, setSelectedTeams] = useState<any[]>([])

  const [query, setQuery] = useState('')

  const filteredTeams =
    query === ''
      ? teams
      : teams.filter((team) =>
          team.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  const handleChange = (e) => {
    setSelectedTeams(e)
    handleFormChange({ name: 'teamIds', value: e.map((t) => t.id) })
  }

  return (
    <Combobox value={selectedTeams} onChange={handleChange} multiple>
      {selectedTeams.length > 0 && (
        <ul className="divide divide-y divide-slate-100 dark:divide-slate-700">
          {selectedTeams.map((team) => (
            <li key={team.id} className="py-2.5 flex justify-between items-center">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-900 dark:text-slate-100">{team.name}</span>
                <span className="text-sm text-gray-500">{team.description}</span>
              </div>
              <div
                className="rounded-md p-1 border border-slate-200 cursor-pointer"
                onClick={() => setSelectedTeams(selectedTeams.filter((t) => t.id !== team.id))}
              >
                <XMarkIcon className="w-4 h-4 text-red-500" />
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="relative">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white border border-gray-300 dark:bg-slate-800 dark:border-slate-600 text-left shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          {isLoading && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-2">
              <Loader size="sm" />
            </div>
          )}
          <Combobox.Input
            className="w-full focus:ring-indigo-500 focus:border-indigo-500 block border-none dark:bg-slate-800 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 dark:text-slate-100 focus:ring-0 sm:text-sm rounded-md"
            displayValue={(team: any) => team.name}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-slate-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredTeams.length === 0 ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-slate-300">
                Nothing found.
              </div>
            ) : (
              filteredTeams.map((team) => (
                <Combobox.Option key={team.id} value={team}>
                  <li key={team.id} className="px-3 py-2 flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-gray-900 dark:text-slate-100">{team.name}</span>
                      <span className="text-sm text-gray-500">{team.description}</span>
                    </div>
                  </li>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
