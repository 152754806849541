import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { useAppDispatch } from 'hooks/redux'
// import { fetchOrganizationMembershipList } from 'slices/organization/reducer'
import { PlusSmallIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

const items = [{ name: 'New project' }, { name: 'New Team' }, { name: 'New Docs' }]

export default function AddResourceDropdown() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // dispatch(fetchOrganizationMembershipList())
  }, [dispatch])

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            // className="inline-flex items-center justify-center rounded-lg bg-slate-400 dark:bg-slate-700 bg-opacity-20 px-1.5 py-1.5 text-sm font-medium dark:text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-slate-700 "
            className="relative w-full inline-flex items-center justify-center rounded-lg text-slate-700 dark:text-white hover:bg-opacity-30 bg-slate-400 dark:bg-slate-700 py-1.5 pl-1.5 pr-7 text-left shadow-md focus:outline-none font-medium"
          >
            <PlusSmallIcon className="h-5 w-5" aria-hidden="true" />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-4 w-4 dark:text-slate-500" aria-hidden="true" />
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 mt-2 w-52 origin-bottom-right rounded-md bg-white dark:bg-slate-700 dark:text-slate-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-96">
            <ul className="divide-y divide-white/5">
              {items.map((item) => (
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={clsx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm dark:text-slate-100',
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </ul>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
