import { useState, useCallback, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
import Button from '../../../components/ui/Button'
import Input from '../../../components/ui/Input'
import InputLabel from '../../../components/ui/InputLabel'
import SelectTeamsInput from './SelectTeamsInput'
import MainPage from 'components/ui/MainPage'
// import { useOrganization } from 'hooks/useOrganization'
import CreateTeamModal from 'components/CreateTeamModal'
import ProjectVisibility from './ProjectVisibility'
import { useOrganization } from 'hooks/useOrganization'
import axios from 'axios'
import { BASE_API_URL } from '../../../constants'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchTeamList } from 'slices/team/reducer'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes/routes'
import PageHeader from 'components/ui/PageHeader'
import Card from 'components/ui/Card'
import GitNamespaceDropdown from './GitNamespaceDropdown'
import { githubAppInstallationPopup } from 'utils/githubSetup'
import { fetchIntegrationList } from 'slices/integration/reducer'
import { LockClosedIcon } from '@heroicons/react/24/outline'

export default function Index() {
  const navigate = useNavigate()
  const authCredentials = useAppSelector((state) => state.auth.authCredentials)
  const [importFromGit, setImportFromGit] = useState(true)
  const [selectedNamespace, setSelectedNamespace] = useState<any>(undefined)

  const { activeOrganization } = useOrganization()
  console.log(activeOrganization, selectedNamespace)
  const [projectForm, setProjectForm] = useState({
    name: '',
    visibility: 'public',
    teamIds: [],
    description: undefined,
  })

  const dispatch = useAppDispatch()
  const activeOrganizationId = useAppSelector((state) => state.organization.activeOrganizationId)
  const teams = useAppSelector((state) => state.team.teams)
  const user = useAppSelector((state) => state.auth.user)
  const integrations = useAppSelector((state) => state.integration.integrations)

  useEffect(() => {
    if (activeOrganizationId) {
      dispatch(fetchIntegrationList({ organizationId: activeOrganizationId }))
      dispatch(fetchTeamList({ organizationId: activeOrganizationId }))
    }
  }, [activeOrganizationId, dispatch])

  const handleFormChange = useCallback(
    ({ name, value }: { name: string; value: any }) => {
      setProjectForm({ ...projectForm, [name]: value })
    },
    [projectForm],
  )

  const handleNamespaceChange = ({ value }) => {
    setSelectedNamespace(value)
  }

  const handleImport = async (repo) => {
    handleSubmit({
      name: repo.name,
      visibility: repo.private ? 'private' : 'internal',
      description: undefined,
      teamIds: [],
      sourceCodeUrl: repo.html_url,
    })
  }

  const handleSubmit = async (form) => {
    const body = form || projectForm
    const { name } = body
    if (name && authCredentials) {
      await axios
        .post(
          `${BASE_API_URL}/projects`,
          { ...body, organizationId: activeOrganizationId },
          {
            headers: { Authorization: `Bearer ${authCredentials.accessToken}` },
          },
        )
        .then(() => navigate(ROUTES.HOME))
    }
  }

  return (
    <MainPage>
      <Button onClick={() => setImportFromGit(!importFromGit)}>Manual</Button>
      {importFromGit ? (
        <div>
          <PageHeader
            title="Lets build something new."
            description="To deploy a new Project, import an existing Git Repository or get started with one of our Templates."
          />
          <div className="md:grid md:grid-cols-12 md:gap-6 my-12">
            <div className="md:col-span-6">
              <Card title="Import Git Repository">
                <div className="md:grid md:grid-cols-12 md:gap-6">
                  <div className="md:col-span-6">
                    <GitNamespaceDropdown namespaces={integrations} handleFormChange={handleNamespaceChange} />
                  </div>
                </div>
                <div>
                  <ul className="divide-y divide-gray-100 max-h-96 overflow-auto">
                    {[...(selectedNamespace?.repos?.repositories || [])]
                      .sort((a, b) => new Date(b?.updated_at).getTime() - new Date(a?.updated_at).getTime())
                      .map((repo) => (
                        <li key={repo.id} className="flex items-center justify-between gap-x-6 py-5">
                          <div className="min-w-0">
                            <div className="flex items-center gap-x-3">
                              <p className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-100">
                                {repo.name}
                              </p>
                              {repo.private && <LockClosedIcon className="w-4 h-4 dark:text-slate-500" />}
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            <Button variant="secondary" onClick={() => handleImport(repo)}>
                              Import<span className="sr-only">, {repo.name}</span>
                            </Button>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="dark:text-slate-400 text-sm mt-3">
                  Missing Git repository?{' '}
                  <Button variant="link" onClick={() => githubAppInstallationPopup({ state: user.id })}>
                    Adjust GitHub App Permissions
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:grid md:grid-cols-3 md:gap-6 mx-4 my-16">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-slate-900 dark:text-slate-100">Project Details</h3>
            <p className="mt-1 text-sm text-slate-500">
              Projects allow you to scope documentation to a specific application in your organization. For example, you
              might have separate projects for your API server and frontend client.
            </p>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <form>
              <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
                <div className="col-span-6 md:col-span-4">
                  <InputLabel label="Name" />
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={projectForm?.name}
                    onChange={(e) => handleFormChange({ name: 'name', value: e.target.value })}
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <ProjectVisibility handleFormChange={handleFormChange} />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <InputLabel label="Teams" />
                  <div className="flex items-center space-x-4 w-full">
                    <div className="flex-1">
                      <SelectTeamsInput teams={teams} isLoading={false} handleFormChange={handleFormChange} />
                    </div>
                    <CreateTeamModal />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <InputLabel label="Description" />
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      maxLength={250}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full dark:text-white sm:text-sm border dark:bg-slate-800 dark:border-slate-600 border-slate-300 rounded-md"
                      placeholder="Describe the project"
                      value={projectForm.description}
                      onChange={(e) => handleFormChange({ name: 'description', value: e.target.value })}
                    />
                  </div>
                  <p className="mt-2 text-sm text-slate-500">Brief description of the project (optional)</p>
                </div>
              </div>
            </form>
          </div>
          <div className="my-4 space-x-3 col-start-2 md:col-span-2">
            <Button variant="secondary">Cancel</Button>
            <Button onClick={handleSubmit}>Create</Button>
          </div>
        </div>
      )}
    </MainPage>
  )
}
