export default function Insights() {
  return (
    <div>
      <div>
        Scorecards: measure and improve your software quality Establish engineering standards and automatically ensure
        software is meeting expectations around security, operations, compliance, deployment and more.
      </div>
      <ul>
        <li>Scorecards</li>
        <li>Checks</li>
      </ul>
    </div>
  )
}
