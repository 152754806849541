import { SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProjectClient } from 'services/projects'
import { Project } from 'services/projects/types'

const initialState: {
  isLoading: boolean
  currentRequestId: string | undefined
  projects: Project[]
  currentProject: Project | undefined
  error: undefined | SerializedError
} = {
  isLoading: false,
  currentRequestId: undefined,
  projects: [],
  currentProject: undefined,
  error: undefined,
}

export const fetchProjectList = createAsyncThunk(
  'projects/fetchList',
  async ({ organizationId }: { organizationId: string }, { getState }): Promise<Project[]> => {
    const state: any = await getState()
    const jwt = state.auth.authCredentials?.accessToken
    const projectClient = new ProjectClient({
      apiKey: jwt,
    })
    const { data }: any = await projectClient.fetchProjectList({ organizationId })
    return data
  },
)

export const fetchProjectDetail = createAsyncThunk(
  'projects/fetchDetail',
  async (
    { organizationId, projectId }: { organizationId: string; projectId: string },
    { getState },
  ): Promise<Project> => {
    const state: any = await getState()
    const jwt = state.auth.authCredentials?.accessToken
    const projectClient = new ProjectClient({
      apiKey: jwt,
    })
    const { data }: any = await projectClient.fetchProjectById({ organizationId, projectId })
    return data
  },
)

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectList.pending, (state, action) => {
        if (!state.isLoading) {
          state.isLoading = true
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(fetchProjectList.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.projects = action.payload
          state.currentRequestId = undefined
        }
      })
      .addCase(fetchProjectList.rejected, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      })
      .addCase(fetchProjectDetail.pending, (state, action) => {
        if (!state.isLoading) {
          state.isLoading = true
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(fetchProjectDetail.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.currentProject = action.payload
          state.currentRequestId = undefined
        }
      })
      .addCase(fetchProjectDetail.rejected, (state, action) => {
        const { requestId } = action.meta
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false
          state.error = action.error
          state.currentRequestId = undefined
        }
      })
  },
})

export default projectSlice.reducer
