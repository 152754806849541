export const githubAppInstallationPopup = ({ state }: { state: string }) => {
  const left = (window.innerWidth - 600) / 2
  const top = (window.innerHeight - 600) / 4
  const popup = window.open(
    `https://github.com/apps/developerportal-io?state=${state}`,
    'popUpWindow',
    `height=600,width=600,left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`,
  )

  const popupTick = setInterval(function () {
    if (popup?.closed) {
      clearInterval(popupTick)
    }
  }, 500)
}
