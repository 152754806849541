import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'

export default function Empty({
  title,
  subtitle,
  action,
}: {
  title: string | ReactNode
  subtitle?: string | ReactNode
  action?: ReactNode
}) {
  return (
    <div className="text-center">
      <FolderPlusIcon className="mx-auto h-12 w-12 text-slate-400" />
      <h3 className="mt-2 text-sm font-medium text-slate-900 dark:text-white">{title}</h3>
      {subtitle && <p className="mt-1 text-sm text-slate-500">{subtitle}</p>}
      {action && <div className="mt-6">{action}</div>}
    </div>
  )
}
