import clsx from 'clsx'
import NavbarItem from './NavbarItem'
import BlockLogo from '../../assets/blockLogo.svg'
import { Link } from 'react-router-dom'
import { ThemeToggle } from 'components/ThemeToggle'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import OrganizationDropdown from 'components/OrganizationDropdown'

export interface NavigationItem {
  name: string
  href: string
  icon: any
  active: boolean
}

const teams = [
  { id: 1, name: 'Developer Portal', href: '#', initial: 'DP', current: false },
  { id: 2, name: 'RealtyStack', href: '#', initial: 'RS', current: false },
]

export default function DesktopSideNavbar({
  navigation,
  sidebarOpen,
  setSidebarOpen,
}: {
  navigation: NavigationItem[]
  sidebarOpen: boolean
  setSidebarOpen: any
}) {
  return (
    <div
      className={clsx(
        'group/navbar hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col dark:lg:bg-slate-800 border-r border-gray-900/10 dark:border-white/5 transition ease-in-out duration-500 transform relative',
        { 'lg:w-60': sidebarOpen },
        { 'lg:w-20': !sidebarOpen },
      )}
    >
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-4 overflow-visible bg-gray-50 dark:bg-black/10 px-4 ring-1 ring-white/5">
        <Link to="/" className={clsx('flex h-16 shrink-0 items-center', { 'justify-center': !sidebarOpen })}>
          <img className="h-8 w-auto" src={BlockLogo} alt="DeveloperPortal" />
        </Link>
        <button
          type="button"
          className="-m-2.5 absolute top-7 right-0 z-50 group-hover/navbar:visible hover:visible invisible h-5 w-5 rounded-full border bg-slate-700 flex justify-center items-center"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <span className="sr-only">Close sidebar</span>
          <ChevronLeftIcon className={clsx('h-3 w-3 text-white', { 'rotate-180': !sidebarOpen })} aria-hidden="true" />
        </button>
        <div className="-mt-4">
          <OrganizationDropdown />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-1 space-y-0.5">
                {navigation.map((item) => (
                  <NavbarItem item={item} sidebarOpen={sidebarOpen} />
                ))}
              </ul>
            </li>
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">Teams</div>
              <ul className="-mx-2 mt-2 space-y-1">
                {teams.map((team) => (
                  <li key={team.name}>
                    <a
                      href={team.href}
                      className={clsx(
                        team?.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      )}
                    >
                      <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                        {team.initial}
                      </span>
                      <span className="truncate">{team.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <ThemeToggle />
            <li className="-mx-6 mt-auto flex justify-between items-center">Support</li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
