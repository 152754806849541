import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './slices/auth/reducer'
import eventReducer from './slices/event/reducer'
import organizationReducer from './slices/organization/reducer'
import teamReducer from './slices/team/reducer'
import projectReducer from './slices/project/reducer'
import integrationReducer from './slices/integration/reducer'

const persistConfig = {
  key: 'root',
  storage,
}

const combinedReducers = combineReducers({
  auth: authReducer,
  event: eventReducer,
  organization: organizationReducer,
  team: teamReducer,
  project: projectReducer,
  integration: integrationReducer,
})

const persistedReducer = persistReducer(persistConfig, combinedReducers)

export const store = configureStore({ reducer: persistedReducer })

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
