import { Link } from 'react-router-dom'
import Avatar from '../../../components/ui/Avatar'
// import MoreMenu from 'components/common/MoreMenu'

export default function TeamListItem({ team }: { team }) {
  const { team_id, name, description, members } = team
  return (
    <li key={team_id} className="block hover:bg-slate-50 dark:hover:bg-slate-700 first:rounded-t-md last:rounded-b-md">
      <Link to={`/settings/teams/${team_id}`}>
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <Avatar text={name} size="lg" variant="square" />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 dark:text-indigo-500 truncate">{name}</p>
                <p className="mt-2 flex items-center text-sm text-slate-500 dark:text-slate-400">
                  <span className="truncate">{description}</span>
                </p>
              </div>
              <div className="hidden md:flex items-center">
                <div className="isolate flex -space-x-2 overflow-hidden">
                  {members.map((m) => (
                    <Avatar text={m.user.email} size="sm" />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>{/* <MoreMenu team={team} /> */}</div>
        </div>
      </Link>
    </li>
  )
}
