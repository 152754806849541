import { createSlice } from '@reduxjs/toolkit'

const initialState: { user: any; authCredentials: any } = {
  user: {},
  authCredentials: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin: (state, action) => {
      const payload = action.payload
      const user = payload.user
      state.user = user
      state.authCredentials = payload
    },
    signout: (state) => {
      state.user = {}
      state.authCredentials = undefined
    },
  },
})

export const { signin, signout } = authSlice.actions

export default authSlice.reducer
