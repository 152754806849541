import { createContext, useState, useEffect, ReactElement } from 'react'
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'

interface Theme {
  name: string
  value: string
  icon: any
}

const lightTheme = { name: 'Light', value: 'light', icon: SunIcon }
const darkTheme = { name: 'Dark', value: 'dark', icon: MoonIcon }

const themes = [lightTheme, darkTheme]

export const ThemeContext = createContext<{ selectedTheme: Theme; toggleTheme: any }>({
  selectedTheme: lightTheme,
  toggleTheme: '',
})

export default function ThemeProvider({ children }: { children: ReactElement }) {
  const persistedTheme = localStorage.getItem('theme')

  const [selectedTheme, setSelectedTheme] = useState<Theme>(
    themes.find((theme) => theme.value === persistedTheme) || lightTheme,
  )

  const toggleTheme = (newThemeId: 'light' | 'dark' | 'system') => {
    const newTheme = themes.find((theme) => theme.value === newThemeId) || lightTheme
    setSelectedTheme(newTheme)
  }

  useEffect(() => {
    if (selectedTheme) {
      document.documentElement.setAttribute('class', selectedTheme.value)
      localStorage.setItem('theme', selectedTheme.value)
    } else {
      setSelectedTheme(
        themes.find((theme) => theme.value === document.documentElement.getAttribute('class')) || lightTheme,
      )
    }
  }, [selectedTheme])

  return (
    <ThemeContext.Provider
      value={{
        selectedTheme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
