import CreateTeamModal from 'components/CreateTeamModal'
import SectionHeader from '../../../components/ui/SectionHeader'
import TeamList from './TeamList'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useEffect } from 'react'
import { fetchTeamList } from 'slices/team/reducer'

function Teams() {
  const dispatch = useAppDispatch()
  const activeOrganizationId = useAppSelector((state) => state.organization.activeOrganizationId)
  const teams = useAppSelector((state) => state.team.teams)

  useEffect(() => {
    if (activeOrganizationId) {
      dispatch(fetchTeamList({ organizationId: activeOrganizationId }))
    }
  }, [activeOrganizationId, dispatch])

  return (
    <div className="mx-6 my-4">
      <SectionHeader
        title={'Teams'}
        actions={
          <>
            <CreateTeamModal />
          </>
        }
      />
      <TeamList teams={teams} isLoading={false} />
    </div>
  )
}

export default Teams
