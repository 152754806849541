import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useEffect } from 'react'
import EventTable from './EventTable'
import { fetchEventList } from 'slices/event/reducer'

function Events() {
  const dispatch = useAppDispatch()
  const activeOrganizationId = useAppSelector((state) => state.organization.activeOrganizationId)
  const events = useAppSelector((state) => state.event.events)

  useEffect(() => {
    if (activeOrganizationId) {
      dispatch(fetchEventList({ organizationId: activeOrganizationId }))
    }
  }, [activeOrganizationId, dispatch])

  return (
    <div className="mx-6 my-4">
      <EventTable isLoading={false} events={events} />
    </div>
  )
}

export default Events
