import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import ThemeProvider from './providers/ThemeProvider'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { BaseErrorBoundary } from './pages/error-boundary'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { REACT_APP_GOOGLE_AUTH_CLIENT_ID } from './constants'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptionsMode, loadStripe } from '@stripe/stripe-js'

const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID
let stripePromise
if (STRIPE_CLIENT_ID) {
  stripePromise = loadStripe(STRIPE_CLIENT_ID)
}

const options: StripeElementsOptionsMode = {
  mode: 'subscription',
  currency: 'usd',
  amount: 1099,
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
            <ThemeProvider>
              <BaseErrorBoundary>
                <Elements stripe={stripePromise} options={options}>
                  <App />
                </Elements>
              </BaseErrorBoundary>
            </ThemeProvider>
          </GoogleOAuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
