import { useContext } from 'react'
import { Switch } from '@headlessui/react'
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'
import { ThemeContext } from '../providers/ThemeProvider'

export function ThemeToggle() {
  const { selectedTheme, toggleTheme } = useContext(ThemeContext)

  const lightThemeSelected = selectedTheme.value === 'light'

  return (
    <Switch
      checked={lightThemeSelected}
      onChange={() => toggleTheme(lightThemeSelected ? 'dark' : 'light')}
      className={`${lightThemeSelected ? 'bg-slate-200' : 'bg-indigo-500'}
          relative inline-flex items-center h-5 w-10 shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <div className="flex items-center justify-center">
        <span
          aria-hidden="true"
          className={`${lightThemeSelected ? 'translate-x-0' : 'translate-x-5'}
            pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-1 ring-slate-200 transition duration-200 ease-in-out`}
        />
        {lightThemeSelected ? (
          <SunIcon className="absolute h-4 w-4 fill-orange-400 transition transform duration-300 ease-in-out" />
        ) : (
          <MoonIcon className="absolute h-4 w-4 fill-indigo-400 translate-x-5 transform transition duration-300 ease-in-out" />
        )}
      </div>
    </Switch>
  )
}
