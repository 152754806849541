import Avatar from '../../../components/ui/Avatar'
import Card from '../../../components/ui/Card'
import Empty from '../../../components/ui/Empty'
import Skeleton from '../../../components/ui/Skeleton'
import IntegrationListItem from './IntegrationListItem'
import CreateIntegrationModal from './CreateIntegrationModal'

export default function IntegrationList({ integrations, isLoading }: { integrations: any[]; isLoading: boolean }) {
  return (
    <Card className="!p-0">
      <ul className="divide-y divide-slate-200 dark:divide-slate-600">
        {isLoading ? (
          Array.from(Array(3)).map((_, idx: number) => (
            <li
              key={idx}
              className="block hover:bg-slate-50 dark:hover:bg-slate-800 first:rounded-t-md last:rounded-b-md"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    <Avatar text={'load'} isLoading={true} size="lg" variant="square" />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div className="space-y-2">
                      <Skeleton className="h-5 w-full" />
                      <Skeleton className="h-5 w-full" />
                    </div>
                    <div className="hidden md:block">
                      <div className="space-y-2">
                        <Skeleton className="h-5 w-full" />
                        <Skeleton className="h-5 w-full" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : integrations && integrations.length ? (
          integrations.map((integration) => <IntegrationListItem integration={integration} />)
        ) : (
          <div className="p-4 sm:p-6">
            <Empty
              title="No integrations"
              subtitle="Get started by connecting an integration."
              action={<CreateIntegrationModal />}
            />
          </div>
        )}
      </ul>
    </Card>
  )
}
