import clsx from 'clsx'
import React from 'react'

export default function Input({
  type = 'text',
  name,
  id,
  value,
  placeholder,
  onChange,
  className,
}: {
  type?: string
  name?: string
  placeholder?: string | undefined
  id?: string
  value?: any
  onChange?: any
  className?: string
}) {
  return (
    <input
      type={type}
      name={name}
      id={id ? id : name ? name.split(' ').join('_').toLowerCase() : undefined}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={clsx(
        'mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm dark:bg-slate-800 dark:text-white dark:border-slate-600 border-slate-300 rounded-md placeholder-slate-400 dark:placeholder-slate-600',
        className,
      )}
    />
  )
}
