import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useCallback, useState } from 'react'
import { PlusIcon } from '@heroicons/react/24/solid'
import Button from './ui/Button'
import InputLabel from './ui/InputLabel'
import Input from './ui/Input'
import axios from 'axios'
import { BASE_API_URL } from '../constants'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchTeamList } from 'slices/team/reducer'

const initialValues = { name: '' }

export default function CreateTeamModal() {
  const dispatch = useAppDispatch()
  // const { organizationId } = useOrganization()
  const authCredentials = useAppSelector((state) => state.auth.authCredentials)
  const activeOrganizationId = useAppSelector((state) => state.organization.activeOrganizationId)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [teamForm, setTeamForm] = useState(initialValues)

  const handleFormChange = useCallback(
    ({ name, value }: { name: string; value: any }) => {
      setTeamForm({ ...teamForm, [name]: value })
    },
    [teamForm],
  )

  const handleSubmit = async () => {
    await axios
      .post(
        `${BASE_API_URL}/teams`,
        { ...teamForm, organizationId: activeOrganizationId },
        {
          headers: { Authorization: `Bearer ${authCredentials.accessToken}` },
        },
      )
      .then((res) => {
        dispatch(fetchTeamList({ organizationId: activeOrganizationId || '' }))
        closeModal()
      })
  }

  function closeModal() {
    setIsOpen(false)
    setTeamForm(initialValues)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <Button onClick={openModal}>
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Team
        </Button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 p-6 text-left align-middle shadow-xl transition-all">
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-slate-100">
                      Create Team
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Teams allow for organizations to group users together to access and maintain projects
                      </p>
                    </div>

                    <div className="mt-6 mb-8">
                      <InputLabel label="Name" />
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={teamForm?.name}
                        onChange={(e) => handleFormChange({ name: 'name', value: e.target.value })}
                      />
                    </div>

                    <div className="flex items-center justify-end space-x-3">
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button type="submit" onClick={handleSubmit}>
                        Create
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
