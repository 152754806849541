import clsx from 'clsx'
import { ReactNode } from 'react'

export default function Card({
  children,
  className,
  title,
  extra,
}: {
  children: ReactNode
  className?: string
  title?: string
  extra?: ReactNode
}) {
  return (
    <div className={clsx('bg-white dark:bg-slate-800 shadow sm:rounded-lg px-4 py-4 sm:p-6', className)}>
      {title && (
        <div className="flex justify-between">
          <div className="pb-3 font-semibold dark:text-slate-100">{title}</div>
          {extra}
        </div>
      )}
      {children}
    </div>
  )
}
