import { ReactNode } from 'react'
import Skeleton from './Skeleton'

export default function PageHeader({
  title,
  description,
  actions,
  isLoading,
}: {
  title: string | undefined
  description?: string | ReactNode
  actions?: ReactNode
  isLoading?: boolean
}) {
  return (
    <div className="pt-5 pb-6 px-4 sm:px-0 lg:flex lg:items-center lg:justify-between">
      <div className="flex-1 min-w-0">
        {isLoading ? (
          <Skeleton className="h-9 w-48" />
        ) : (
          <h2 className="text-2xl font-bold !leading-10 text-gray-900 dark:text-white sm:text-3xl sm:tracking-tight sm:truncate">
            {title}
          </h2>
        )}
        {description && (
          <div className="mt-2 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="flex items-center text-sm text-gray-500">{description}</div>
          </div>
        )}
      </div>
      {actions && <div className="mt-5 flex lg:mt-0 lg:ml-4">{actions}</div>}
    </div>
  )
}
