const sizes = ['sm', 'md', 'lg', 'xl'] as const

// eslint-disable-next-line prettier/prettier
type Size = (typeof sizes)[number]

export default function Loader({ size = 'md' }: { size?: Size }) {
  const sizeMap = {
    sm: 'w-5 h-5 border-2',
    md: 'w-8 h-8 border-2',
    lg: 'w-10 h-10 border-4',
    xl: 'w-15 h-15',
  }
  return (
    <div
      className={`${sizeMap[size]} align-middle animate-spin spinner-border inline-block border-indigo-200 border-t-indigo-400 dark:border-indigo-400 dark:border-t-indigo-300 rounded-full`}
      role="status"
    >
      <span className="hidden">Loading...</span>
    </div>
  )
}
