import Avatar from './ui/Avatar'
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchOrganizationMembershipList, updateActiveOrganizationId } from 'slices/organization/reducer'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import CreateOrganizationModal from './CreateOrganizationModal'
import { usePrevious } from 'hooks/usePrevious'

export default function OrganizationDropdown() {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)
  const prevUser = usePrevious(user)
  const activeOrganizationId = useAppSelector((state) => state.organization.activeOrganizationId)
  const memberships = useAppSelector((state) => state.organization.memberships)

  const activeOrganization =
    memberships.find((m) => m.organization.id.toLowerCase() === activeOrganizationId?.toLowerCase()) ||
    memberships.find((m) => m.organization.name.toLowerCase() === user.email?.toLowerCase())
  const personalWorkspace = memberships.find((m) => m.organization.name.toLowerCase() === user.email?.toLowerCase())
  const organizations = memberships.filter((m) => m.organization.name.toLowerCase() !== user.email?.toLowerCase())

  useEffect(() => {
    if (user?.id !== prevUser?.id) {
      dispatch(fetchOrganizationMembershipList())
    }
  }, [dispatch, user, prevUser])

  const handleOrganizationChange = async (organizationId: string) => {
    dispatch(updateActiveOrganizationId(organizationId))
  }
  return (
    <>
      <Menu as="div" className="relative inline-block text-left w-full">
        <div>
          <Menu.Button className="group w-full rounded-lg bg-slate-200 dark:bg-slate-700 px-2 py-1.5 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 overflow-visible">
            <span className="flex w-full items-center justify-between">
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <Avatar size="md" variant="square" text={activeOrganization?.organization?.name || ''} />
                <span className="flex min-w-0 flex-1 flex-col">
                  <span className="truncate text-sm font-medium text-slate-900 dark:text-slate-100">
                    {activeOrganization?.organization?.name}
                  </span>
                  <span className="truncate text-sm text-slate-500 dark:text-slate-400">
                    {user?.name || user.email}
                  </span>
                </span>
              </span>
              <ChevronUpDownIcon
                className={clsx('h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500')}
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-50 mt-2 w-56 origin-bottom-left rounded-md bg-white dark:bg-slate-700 dark:text-slate-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-visible">
            <div className="px-1 pt-2 pb-1">
              <div className="text-xs text-slate-400 font-medium px-1.5 py-1">Personal Workspace</div>

              <Menu.Item>
                <div
                  className={clsx(
                    'flex space-x-2 text-sm p-1.5 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-600 rounded-md',
                    {
                      'bg-slate-200 dark:bg-slate-800':
                        activeOrganization?.organization?.id === personalWorkspace?.organization?.id,
                    },
                  )}
                  onClick={() => handleOrganizationChange(personalWorkspace?.organization?.id)}
                >
                  <Avatar size="sm" variant="square" text={personalWorkspace?.organization?.name} />
                  <div className="flex flex-col text-left justify-start">
                    <div className="dark:text-slate-100">{personalWorkspace?.organization?.name}</div>
                    <div className="text-slate-500 dark:text-slate-300">{personalWorkspace?.role}</div>
                  </div>
                </div>
              </Menu.Item>
            </div>
            {organizations.length > 0 && (
              <div className="px-1 pb-2 pt-1.5">
                <div className="text-xs text-slate-400 font-medium px-1.5 py-1">Organizations</div>
                {organizations.map((m) => (
                  <Menu.Item key={m.id}>
                    <div
                      className={clsx(
                        'flex space-x-2 text-sm p-1.5 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-600 rounded-md',
                        {
                          'bg-slate-200 dark:bg-slate-800': activeOrganization?.organization?.id === m?.organization.id,
                        },
                      )}
                      onClick={() => handleOrganizationChange(m?.organization.id)}
                    >
                      <Avatar size="sm" variant="square" text={m?.organization.name} />
                      <div className="flex flex-col text-left justify-start">
                        <div className="dark:text-slate-100">{m?.organization.name}</div>
                        <div className="text-slate-500 dark:text-slate-300">{m?.role}</div>
                      </div>
                    </div>
                  </Menu.Item>
                ))}
              </div>
            )}
            <Menu.Item>
              <CreateOrganizationModal />
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
