import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { NavigationItem } from './DesktopSideNavbar'

export default function NavbarItem({ item, sidebarOpen }: { item: NavigationItem; sidebarOpen?: boolean }) {
  const location = useLocation()
  const isActive = location.pathname === item.href
  return (
    <Link
      key={item.name}
      to={item.href}
      className={clsx(
        isActive
          ? 'bg-slate-200 dark:bg-slate-700 text-slate-800 dark:text-slate-100'
          : 'text-slate-600 hover:text-slate-900 hover:bg-slate-50 dark:text-slate-300 dark:hover:text-slate-100 dark:hover:bg-slate-900',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md space-x-3',
        { 'justify-center space-x-0': !sidebarOpen },
      )}
      aria-current={isActive ? 'page' : undefined}
    >
      <item.icon
        className={clsx(
          isActive ? 'text-slate-500' : 'text-slate-400 group-hover:text-slate-500',
          'flex-shrink-0 h-6 w-6',
        )}
        aria-hidden="true"
      />
      {sidebarOpen && <div>{item.name}</div>}
    </Link>
  )
}
