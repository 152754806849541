import { Fragment, ReactElement, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ChartBarIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import BlockLogo from '../../assets/blockLogo.svg'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import Avatar from 'components/ui/Avatar'
import Header from './Header'
import clsx from 'clsx'
import DesktopSideNavbar from './DesktopSideNavbar'
import { ROUTES } from 'routes/routes'
import { Navigate } from 'react-router-dom'
import { BookOpenIcon } from '@heroicons/react/24/outline'
import { signout } from 'slices/auth/reducer'

const navigation: any = [
  { name: 'Home', href: '/', icon: HomeIcon, active: false },
  { name: 'Projects', href: '/projects', icon: Squares2X2Icon, active: false },
  { name: 'Docs', href: '/docs', icon: BookOpenIcon, active: false },
  { name: 'Templates', href: '/templates', icon: DocumentDuplicateIcon, active: false },
  { name: 'Insights', href: '/insights', icon: ChartBarIcon, active: false },
  { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, active: false },
]

const teams = [
  { id: 1, name: 'Developer Portal', href: '#', initial: 'DP', current: false },
  { id: 2, name: 'RealtyStack', href: '#', initial: 'RS', current: false },
]

export default function Dashboard({ children }: { children: ReactElement }) {
  const dispatch = useAppDispatch()

  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(true)
  const { user, authCredentials } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (!user?.id || !authCredentials || parseInt(authCredentials.accessTokenClaims.exp) * 1000 < Date.now()) {
      dispatch(signout())
    }
  }, [authCredentials, dispatch, user?.id])

  if (!user?.id || !authCredentials) {
    return <Navigate to={ROUTES.SIGNIN} />
  }

  return (
    <>
      <div className="h-full dark:bg-gray-900">
        <Transition.Root show={mobileSidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setMobileSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setMobileSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img className="h-8 w-auto" src={BlockLogo} alt="Your Company" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={clsx(
                                    item?.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={clsx(
                                    team?.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  )}
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="-mx-6 mt-auto">
                          <a
                            href="#"
                            className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                          >
                            <Avatar text={user?.name || ''} imageUrl={user?.picture} />
                            <span className="sr-only">Your profile</span>
                            <span aria-hidden="true">{user?.email}</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <DesktopSideNavbar navigation={navigation} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />

        <div className={clsx({ 'lg:pl-60': sidebarOpen, 'lg:pl-20': !sidebarOpen }, 'bg-white dark:bg-gray-900')}>
          <Header />
          {children}
        </div>
      </div>
    </>
  )
}
