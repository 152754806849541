import { Bars3Icon } from '@heroicons/react/20/solid'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import AccountDropdown from 'components/AccountDropdown'
import AddResourceDropdown from 'components/AddResourceDropdown'
import CommandPalette from 'components/CommandPalette'
import NotificationDropdown from 'components/NotificationDropdown'
import { useAppSelector } from 'hooks/redux'
import { useState } from 'react'
import { ROUTES_CONFIG } from 'routes/routes'

export default function Header() {
  const { user } = useAppSelector((state) => state.auth)
  const currentProject = useAppSelector((state) => state.project.currentProject)

  const [open, setOpen] = useState(false)

  const handleSearchClick = () => {
    setOpen(true)
  }
  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-900/10 dark:border-white/5 dark:bg-gray-900 px-4 dark:shadow-sm sm:px-6 lg:px-8">
      <button type="button" className="-m-2.5 p-2.5 text-white xl:hidden">
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-5 w-5" aria-hidden="true" />
      </button>

      <div className="flex flex-1 gap-x-4 lg:gap-x-6 justify-between">
        <div className="flex items-center dark:text-white font-medium">
          {ROUTES_CONFIG.find((r) => r.path === window.location.pathname)?.title || currentProject?.name}
        </div>
        <CommandPalette isOpen={open} />
        <div className="flex items-center justify-end space-x-3">
          <div className="flex items-center">
            <div className="relative flex items-center">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 ml-2 left-0 h-full w-5 text-gray-500"
                aria-hidden="true"
              />
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Type / to search"
                className="block w-full rounded-md border-0 pl-9 py-1 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:ring-slate-700"
                onClick={handleSearchClick}
              />
              <div className="absolute inset-y-0 right-0 flex py-1 pr-1.5">
                <kbd className="inline-flex items-center rounded border border-slate-200 px-1 font-sans text-xs text-gray-400 dark:border-slate-700">
                  ⌘K
                </kbd>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:h-6 lg:w-px bg-slate-300 dark:bg-slate-700" aria-hidden="true" />
          <AddResourceDropdown />
          <NotificationDropdown />
          <AccountDropdown user={user} />
        </div>
      </div>
    </div>
  )
}
