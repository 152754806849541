export default function InputLabel({ label }: { label: string }) {
  return (
    <label
      htmlFor={label.split(' ').join('_').toLowerCase()}
      className="block text-sm font-medium text-gray-700 dark:text-slate-300 mb-1"
    >
      {label}
    </label>
  )
}
